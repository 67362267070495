import * as React from 'react';
import { TReactFCP, TReactFCR } from '../../lib/types/utils';
import { CareerSummaryHeader, TCareerSummaryHeaderP } from './SummaryHeader';
import { UseStyles } from '../../lib/types/mui';
import { Theme, Grid, makeStyles } from '@material-ui/core';
import { TechStackTag } from '../TechStackTag';
import clsx from 'clsx';

type ClassKey = 'container' | 'gutterBottom';
const useStyles = makeStyles<Theme, ClassKey>((theme: Theme) => ({
    container: { padding: theme.spacing(1) },
    gutterBottom: { marginBottom: theme.baseline.blockMarginBottom },
}));

export type TCareerSummaryP = TCareerSummaryHeaderP & {
    className?: string;
    stack: string[];
    gutterBottom?: boolean;
};

export function CareerSummary(props: TReactFCP<TCareerSummaryP>): TReactFCR {
    const styles: UseStyles<ClassKey> = useStyles();
    const { className, stack, gutterBottom = false, ...headerProps }: TCareerSummaryP = props!;
    return (
        <div className={clsx(styles.container, { [styles.gutterBottom]: gutterBottom }, className)}>
            <CareerSummaryHeader {...headerProps} />
            <div className={styles.gutterBottom}>{props.children}</div>
            <Grid container spacing={1}>
                {stack.map((s: string) => <Grid key={s} item><TechStackTag label={s} /></Grid>)}
            </Grid>
        </div>
    );
}
