import * as React from 'react';
import { TReactFCP, TReactFCR } from '../../lib/types/utils';
import { useStaticQuery, graphql } from 'gatsby';
import { GQLOasisSummaryQuery } from '../../lib/types/graphql/__generated__/gatsby.gql';
import { DoubleChevronRightIcon } from '../FAIcons';
import { List, ListItem, ListItemText } from '../List';
import { CareerSummary } from './CareerSummary';
import { Link } from '../Link';
import { Typography } from '@material-ui/core';

export type TOasisSummaryP = {};

export function OasisSummary(props: TReactFCP<TOasisSummaryP>): TReactFCR {
    const { firstPosition, secondPosition }: GQLOasisSummaryQuery = useStaticQuery(graphql`
        query OasisSummary {
            firstPosition: careerYaml(cid: {eq: "oasis-advanced-engineering-1"}) {
                startDate(formatString: "MMM YYYY")
                endDate(formatString: "MMM YYYY")
                title
                titleQualifier
                stack
            }
            secondPosition: careerYaml(cid: {eq: "oasis-advanced-engineering-2"}) {
                startDate(formatString: "MMM YYYY")
                endDate(formatString: "MMM YYYY")
                title
                titleQualifier
                stack
            }
        }
    `);
    const {
        startDate: startDate1, endDate: endDate1, title: title1, titleQualifier: titleQualifier1, stack: stack1
    }: NonNullable<GQLOasisSummaryQuery['firstPosition']> = firstPosition!;
    const {
        startDate: startDate2, endDate: endDate2, title: title2, titleQualifier: titleQualifier2, stack: stack2
    }: NonNullable<GQLOasisSummaryQuery['secondPosition']> = secondPosition!;
    return (
        <React.Fragment>
            <Typography paragraph>
                Looking back, the projects I worked on at{` `}
                <Link variant='external' to='https://www.oasis-ae.com/'>Oasis</Link> were some of my favorites. Building
                software to{` `}
                <Link variant='external' to='https://www.oasis-ae.com/embedded-training'>
                    simulate combat situations
                </Link>
                {` `}and using the actual armored vehicles to perform end to end tests
                was wild. Writing firmware and graphics drivers for custom logic boards which control electrified
                switchable displays and external FLIR cameras has spurred my interest in microcontrollers and SoCs ever
                since.
            </Typography>
            <CareerSummary
                startDate={startDate2!}
                endDate={endDate2}
                title={title2!}
                subtitle={titleQualifier2!}
                stack={(stack2! as string[]) || []}
                gutterBottom
            >
                <List
                    disablePadding
                    paragraph
                    icon={{
                        Component: DoubleChevronRightIcon,
                        props: { fixedWidth: true, size: 'xs' }
                    }}
                >
                    <ListItem disableGutters>
                        <ListItemText
                            primary={
                                <React.Fragment>
                                    Led development of embedded software within a modern military vehicle periscope to
                                    display and control a Heads Up Display UI and external{` `}
                                    <Link variant='external' to='https://www.flir.com/'>
                                        Forward Looking Infrared Cameras
                                    </Link>
                                    {` on custom `}
                                    <Link variant='external' to='https://en.wikipedia.org/wiki/Atmel'>Atmel-based</Link>
                                    {` `}silicon
                                </React.Fragment>
                            }
                        />
                    </ListItem>
                </List>
            </CareerSummary>
            <CareerSummary
                startDate={startDate1!}
                endDate={endDate1}
                title={title1!}
                subtitle={titleQualifier1!}
                stack={(stack1! as string[]) || []}
                gutterBottom
            >
                <List
                    disablePadding
                    paragraph
                    icon={{
                        Component: DoubleChevronRightIcon,
                        props: { fixedWidth: true, size: 'xs' }
                    }}
                >
                    <ListItem disableGutters>
                        <ListItemText
                            primary={
                                <React.Fragment>
                                    Conceptualized, designed, and implemented a TCP/IP interprocess communication
                                    manager responsible for controlling all stateful messages within the{` `}
                                    <Link variant='external' to='https://www.oasis-ae.com/embedded-training'>
                                        Common Embedded Training System
                                    </Link>
                                    , a heavy vehicle combat simulation software for the Bradley Fighting Vehicle
                                </React.Fragment>
                            }
                        />
                    </ListItem>
                    <ListItem disableGutters>
                        <ListItemText
                            primary={`
                                Developed a program-wide execution loop controller responsible for syncing all
                                components with the refresh rate of the graphical user interface
                            `}
                        />
                    </ListItem>
                </List>
            </CareerSummary>
        </React.Fragment >
    );
}
