import * as React from 'react';
import { TReactFCP, TReactFCR } from '../../lib/types/utils';
import { useStaticQuery, graphql } from 'gatsby';
import { GQLDodSummaryQuery } from '../../lib/types/graphql/__generated__/gatsby.gql';
import { CareerSummary } from './CareerSummary';
import { List, ListItem, ListItemText } from '../List';
import { DoubleChevronRightIcon } from '../FAIcons';
import { Link } from '../Link';
import { Typography } from '../Typography';

export type TDodSummaryP = {};

export function DodSummary(props: TReactFCP<TDodSummaryP>): TReactFCR {
    const { firstPosition, secondPosition }: GQLDodSummaryQuery = useStaticQuery(graphql`
        query DodSummary {
            firstPosition: careerYaml(cid: {eq: "us-dod-1"}) {
                startDate(formatString: "MMM YYYY")
                endDate(formatString: "MMM YYYY")
                title
                stack
            }
            secondPosition: careerYaml(cid: {eq: "us-dod-2"}) {
                startDate(formatString: "MMM YYYY")
                endDate(formatString: "MMM YYYY")
                title
                stack
            }
        }
    `);
    const {
        startDate: startDate1, endDate: endDate1, title: title1, stack: stack1
    }: NonNullable<GQLDodSummaryQuery['firstPosition']> = firstPosition!;
    const {
        startDate: startDate2, endDate: endDate2, title: title2, stack: stack2
    }: NonNullable<GQLDodSummaryQuery['secondPosition']> = secondPosition!;
    return (
        <React.Fragment>
            <Typography paragraph>
                Working at the DoD was an incredibly unique, eye opening, and humbling experience. The depth of
                computer security and privacy knowledge I gained has helped me ensure my software is privacy focused
                and secure by design. Building data analysis software for massive datacenters taught me how to architect
                systems for scalability. Joining an internal accelerator to modernize systems and build products that
                people want revealed my entrepreneurial interests and changed the course of my entire professional
                career.
            </Typography>
            <CareerSummary
                startDate={startDate2!}
                endDate={endDate2}
                title={title2!}
                stack={(stack2! as string[]) || []}
                gutterBottom
            >
                <List
                    disablePadding
                    paragraph
                    icon={{
                        Component: DoubleChevronRightIcon,
                        props: { fixedWidth: true, size: 'xs' }
                    }}
                >
                    <ListItem disableGutters>
                        <ListItemText
                            primary={`
                                Developed the web UI and back-end databases for a service used to display historical
                                and real-time metrics about job progression, cluster statistics, and service health
                                information for 1000+ node Hadoop clusters used by analysts to measure project success
                                and mission impact
                            `}
                        />
                    </ListItem>
                    <ListItem disableGutters>
                        <ListItemText
                            primary={
                                <React.Fragment>
                                    Authored and iterated on business models, value propositions, and product prototypes
                                    though customer interviews, prototype engagement experiments, and product partner
                                    discussions during the government’s{` `}
                                    <Link variant='external' to='https://www.nsf.gov/news/special_reports/i-corps/'>
                                        I-Corps
                                    </Link>
                                    {` `}“Lean Launchpad” 2015 accelerator
                                </React.Fragment>
                            }
                        />
                    </ListItem>
                    <ListItem disableGutters>
                        <ListItemText
                            primary={`
                                Led product modernization effort to research, design, implement, and test full stack
                                technologies suitable to be used within a new web service based on the discoveries made
                                about our customers and user base during I-Corps.
                            `}
                        />
                    </ListItem>
                    <ListItem disableGutters>
                        <ListItemText
                            primary={`
                                Analyzed the performance of Hadoop clusters using load simulation and analytic replay
                            `}
                        />
                    </ListItem>
                    <ListItem disableGutters>
                        <ListItemText
                            primary={`
                                Led efforts to secure the enterprise software development stack through implementation
                                of open source governance policies, creation of the static security analysis services,
                                and served as the corporate developer representative on the Secure Development
                                Lifecycle board
                            `}
                        />
                    </ListItem>
                </List>
            </CareerSummary>
            <CareerSummary
                startDate={startDate1!}
                endDate={endDate1}
                title={title1!}
                stack={(stack1! as string[]) || []}
                gutterBottom
            >
                <List
                    disablePadding
                    paragraph
                    icon={{
                        Component: DoubleChevronRightIcon,
                        props: { fixedWidth: true, size: 'xs' }
                    }}
                >
                    <ListItem disableGutters>
                        <ListItemText
                            primary={`
                                Built a Microsoft Windows-based Computer Network Exploitation (CNE) application
                            `}
                        />
                    </ListItem>
                    <ListItem disableGutters>
                        <ListItemText
                            primary={`
                                Reduced application compilation time by 90% and enhanced compiled binary security
                                measures with custom compilation systems
                            `}
                        />
                    </ListItem>
                    <ListItem disableGutters>
                        <ListItemText
                            primary={`
                                Increased product use by 16% and sped up the development cycle by automating release
                                processes, establishing minimum viable product requirements, and switching to a more
                                frequent release schedule
                            `}
                        />
                    </ListItem>
                    <ListItem disableGutters>
                        <ListItemText
                            primary={`
                                Conceptualized, designed, and led a product rewrite to simplify code, enable
                                extendibility, and increase maintainability for developers
                            `}
                        />
                    </ListItem>
                    <ListItem disableGutters>
                        <ListItemText
                            primary={`
                                Created an automated unit and end-to-end testing suite which eliminated over 80% of
                                manual product testing
                            `}
                        />
                    </ListItem>
                    <ListItem disableGutters>
                        <ListItemText
                            primary={(
                                <React.Fragment>
                                    Received a Performance and Innovation Award for <i>incredible ingenuity and
                                    exceptional contributions to the counterterrorism mission through design,
                                    implementation and integration of multiple high priority customer interests into
                                    product releases</i>
                                </React.Fragment>
                            )}
                        />
                    </ListItem>
                    <ListItem disableGutters>
                        <ListItemText
                            primary={`
                                Held technical and non-technical training courses for product customers, owners,
                                and stakeholders and spoke at internal conferences to share technical achivements, new
                                capabilities, and future challenges.
                            `}
                        />
                    </ListItem>
                    <ListItem disableGutters>
                        <ListItemText
                            primary={`
                                Served as a mentor to many military, civilian, and intern software developers
                            `}
                        />
                    </ListItem>
                </List>
            </CareerSummary>
        </React.Fragment>
    );
}
