import * as React from 'react';
import { TReactFCP, TReactFCR } from '../../lib/types/utils';
import { useStaticQuery, graphql } from 'gatsby';
import { GQLPaddockTechSummaryQuery } from '../../lib/types/graphql/__generated__/gatsby.gql';
import { CareerSummary } from './CareerSummary';
import { DoubleChevronRightIcon } from '../FAIcons';
import { List, ListItem, ListItemText } from '../List';
import { Link } from '../Link';
import { Typography } from '../Typography';
import { ProjectsCardGrid } from '../ProjectsCardGrid';
import { TProjectWork } from '../../data/projects';
import { FluidObject } from 'gatsby-image';

type TProjectsEdge = GQLPaddockTechSummaryQuery['projects']['edges'][0];

export type TPaddockTechSummaryP = {};

export function PaddockTechSummary(props: TReactFCP<TPaddockTechSummaryP>): TReactFCR {
    const { careerYaml, projects, sitePage }: GQLPaddockTechSummaryQuery = useStaticQuery(graphql`
        query PaddockTechSummary {
            careerYaml(cid: {eq: "paddock-tech"}) {
                startDate(formatString: "MMM YYYY")
                endDate(formatString: "MMM YYYY")
                title
                stack
            }
            # I don't like this dependence on the name of the projects page component, but it works for now
            sitePage(internalComponentName: {eq: "ComponentProjects"}) {
                path
            }
            projects: allProjectsYaml(filter: {pid: {in: ["trackside", "gtm-ctrl"]}}) {
                edges {
                    node {
                        pid
                        slug
                        title
                        category
                        type
                        spoiler
                        description
                        stack
                        links {
                            homepage
                            repository
                        }
                        isFeatured
                        featuredImage {
                            childImageSharp {
                                fluid(maxWidth: 768) {
                                    ...GatsbyImageSharpFluid_withWebp
                                }
                            }
                        }
                        featuredImageGrayscale: featuredImage {
                            childImageSharp {
                                fluid(maxWidth: 600, grayscale: true) {
                                    ...GatsbyImageSharpFluid_withWebp
                                }
                            }
                        }
                    }
                }
            }
        }
    `);
    const projectsSlug: string = sitePage!.path!;
    const { startDate, endDate, title, stack }: NonNullable<GQLPaddockTechSummaryQuery['careerYaml']> = careerYaml!;
    return (
        <React.Fragment>
            <CareerSummary
                startDate={startDate!}
                endDate={endDate}
                title={title!}
                stack={(stack! as string[]) || []}
                gutterBottom
            >
                <Typography paragraph>
                    As a solo, self-funded entrepreneur I've been responsible for every part of the creation,
                    development, and management of{` `}
                    <Link variant='external' to='https://paddock.tech/'>Paddock Tech</Link>
                    .
                </Typography>
                <Typography paragraph>From a side project in 2017 to full time in Fall 2018 I've</Typography>
                <List
                    disablePadding
                    paragraph
                    icon={{ Component: DoubleChevronRightIcon, props: { fixedWidth: true, size: 'xs' } }}
                >
                    <ListItem disableGutters>
                        <ListItemText
                            primary={
                                <React.Fragment>
                                    Built Paddock Tech from ideation to its{` `}
                                    <Link variant='internal' to='/projects/trackside/'>first developed product</Link>
                                </React.Fragment>
                            }
                        />
                    </ListItem>
                    <ListItem disableGutters>
                        <ListItemText primary={
                            <React.Fragment>
                                {`Designed the UI and UX of the Paddock Tech `}
                                <Link variant='external' to='https://app.paddock.tech/'>app</Link>{`, `}
                                <Link variant='external' to='https://paddock.tech/'>website</Link>{`, and `}
                                <Link variant='external' to='https://preview.paddock.tech/'>marketing pages</Link>
                            </React.Fragment>
                        } />
                    </ListItem>
                    <ListItem disableGutters>
                        <ListItemText primary={
                            <React.Fragment>
                                Created and executed on the company's vision, mission, sales, and marketing strategies
                            </React.Fragment>
                        } />
                    </ListItem>
                    <ListItem disableGutters>
                        <ListItemText primary={
                            <React.Fragment>
                                Conducted customer outreach, interviewed potential customers, and performed market
                                and competitor analysis to inform business strategies and product priorities
                            </React.Fragment>
                        } />
                    </ListItem>
                </List>
                <Typography>
                    {` In an effort to share and learn in public I've gone into much greater detail across many posts in
                    my `}
                    <Link variant='internal' to='/blog/'>blog</Link>.
                </Typography>
            </CareerSummary>
            <ProjectsCardGrid
                featuredProjects={
                    projects.edges.map((e: TProjectsEdge, index: number) => ({
                        pid: e.node.pid!,
                        card: {
                            // Must include trailing slash since the slugs we get from GQL don't have them
                            slug: `${projectsSlug}${e.node.slug!}/`,
                            category: e.node.category!,
                            type: e.node.type!,
                            title: e.node.title!,
                            spoiler: e.node.spoiler!,
                            description: e.node.description!,
                            stack: e.node.stack! as string[],
                            links: e.node.links! as TProjectWork['links'],
                            featuredImage: {
                                fluid: e.node.featuredImage!.childImageSharp!.fluid! as FluidObject
                            },
                            featuredImageGrayscale: {
                                fluid: e.node.featuredImageGrayscale!.childImageSharp!.fluid! as FluidObject
                            },
                            orientation: index % 2 === 0 ? 'left' : 'right'
                        }
                    }))
                }
            />
        </React.Fragment>
    );
}
