import * as React from 'react';
import { UseStyles } from '../lib/types/mui';
import { Theme, Tabs, Tab, Grid, makeStyles } from '@material-ui/core';
import { TReactFCP, TReactFCR, TReactUSR } from '../lib/types/utils';
import { SEO } from '../components/app/SEO';
import { Page } from '../components/app/Page';
import { Typography } from '../components/Typography';
import { graphql } from 'gatsby';
import { useBreakpointWidth } from '../hooks/useWidth';
import { Breakpoint } from '@material-ui/core/styles/createBreakpoints';
import { TabsProps } from '@material-ui/core/Tabs';
import { TabProps } from '@material-ui/core/Tab';
import { QntfySummary } from '../components/career/QntfySummary';
import { DodSummary } from '../components/career/DodSummary';
import { OasisSummary } from '../components/career/OasisSummary';
import { MsuSummary } from '../components/career/MsuSummary';
import { PaddockTechSummary } from '../components/career/PaddockTechSummary';
import { GQLCareerPageQuery } from '../lib/types/graphql/__generated__/gatsby.gql';
import { Link } from '../components/Link';

type StyleProps = { isWideLayout: boolean; };
type ClassKey = 'main' | 'title' | 'tabsContainer' | 'tabs' | 'tab' | 'tabLabel' | 'content';
const useStyles = makeStyles<Theme, StyleProps, ClassKey>((theme: Theme) => ({
    main: {
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'nowrap',
        padding: theme.spacing(4, 4, 7, 4),
        width: '100%',
        maxWidth: '45em',
        margin: '0 auto'
    },
    title: { marginBottom: theme.spacing(5) },
    tabsContainer: { width: '100%' },
    tabs: {},
    tab({ isWideLayout }: StyleProps) {
        return {
            minWidth: 'unset', maxWidth: '150px', textAlign: isWideLayout ? 'left' : 'center',
        };
    },
    tabLabel: { alignItems: 'stretch' },
    content({ isWideLayout }: StyleProps) {
        return {
            paddingLeft: isWideLayout ? theme.spacing(4) : 'inherit',
            paddingTop: isWideLayout ? 'inherit' : theme.spacing(4),
        };
    }
}));

type TabIndex = 0 | 1 | 2 | 3 | 4;
type THandleChangeFn = (event: React.ChangeEvent<{}>, value: TabIndex) => void;

export type TCareerP = {
    data: GQLCareerPageQuery;
};

function Career(props: TReactFCP<TCareerP>): TReactFCR {
    const [currentTab, setCurrentTab]: TReactUSR<TabIndex> = React.useState(0 as TabIndex);
    const handleChange: THandleChangeFn = React.useCallback(
        (event: React.ChangeEvent<{}>, value: TabIndex) => {
            if (value !== currentTab) {
                setCurrentTab(value);
            }
        },
        [currentTab, setCurrentTab]
    );
    const { sitePage, careerHero }: GQLCareerPageQuery = props.data;
    const width: Breakpoint = useBreakpointWidth();
    const isWideLayout: boolean = width !== 'xs';
    const styles: UseStyles<ClassKey, StyleProps> = useStyles({ isWideLayout });
    const orientation: TabsProps['orientation'] = isWideLayout ? 'vertical' : 'horizontal';
    const tabClasses: TabProps['classes'] = { root: styles.tab, wrapper: styles.tabLabel };
    return (
        <main className={styles.main}>
            <SEO
                slug={sitePage!.path!}
                title={'Career'}
                description={`
                    Building software in the public and private sector for more than 10 years. Wantrepreneur turning
                    entrepreneur.
                `}
                image={{
                    src: careerHero!.childImageSharp!.original!.src!,
                    width: careerHero!.childImageSharp!.original!.width!,
                    height: careerHero!.childImageSharp!.original!.height!,
                }}
            />
            <div className={styles.title}>
                <Typography variant='h1' align='center'>Career</Typography>
                <Typography variant='subtitle2' color='lighter' align='center'>
                    My work life, snack sized.
                </Typography>
                <Typography variant='subtitle2' color='lighter' align='center'>
                    <b>Pssst!</b> I've hidden juicy project details{` `}
                    <Link variant='internal' to='/projects/'>over here</Link>!
                </Typography>
            </div>
            <Grid className={styles.tabsContainer} container wrap='nowrap' direction={isWideLayout ? 'row' : 'column'}>
                <Grid item>
                    <Tabs
                        className={styles.tabs}
                        value={currentTab}
                        orientation={orientation}
                        onChange={handleChange}
                        variant='scrollable'
                        scrollButtons='on'
                    >
                        <Tab classes={tabClasses} label={'Paddock Tech'} />
                        <Tab classes={tabClasses} label={'Qntfy'} />
                        <Tab classes={tabClasses} label={'U.S. Department of Defense'} />
                        <Tab classes={tabClasses} label={'Oasis Advanced Engineering'} />
                        <Tab classes={tabClasses} label={'Michigan State University'} />
                    </Tabs>
                </Grid>
                <Grid item xs className={styles.content}>
                    {currentTab === 0 && <PaddockTechSummary />}
                    {currentTab === 1 && <QntfySummary />}
                    {currentTab === 2 && <DodSummary />}
                    {currentTab === 3 && <OasisSummary />}
                    {currentTab === 4 && <MsuSummary />}
                </Grid>
            </Grid>
        </main>
    );
}

export default (props: TReactFCP<TCareerP>): TReactFCR => <Page component={Career} cprops={props} />;

export const query = graphql`
    query CareerPage {
        sitePage(internalComponentName: {eq: "ComponentCareer"}) {
            path
        }
        careerHero: file(relativePath: {eq: "images/career-hero.jpg"}) {
            childImageSharp {
                original {
                    src
                    width
                    height
                }
            }
        }
    }
`;
