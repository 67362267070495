import * as React from 'react';
import clsx from 'clsx';
import { TReactFCP, TReactFCR } from '../../lib/types/utils';
import { Typography } from '../Typography';
import { Grid, Theme, makeStyles } from '@material-ui/core';
import { UseStyles } from '../../lib/types/mui';
import { LongArrowRightIcon } from '../FAIcons';

type ClassKey = 'header' | 'dates' | 'titleContainer' | 'subtitle';
const useStyles = makeStyles<Theme, ClassKey>((theme: Theme) => ({
    header: { marginBottom: theme.baseline.blockMarginBottom },
    titleContainer: {
        display: 'inline-block',
        marginRight: theme.spacing(4)
    },
    subtitle: { ...theme.typography.sizing(2, 'heading') },
    dates: { display: 'inline-flex', width: 'auto' },
}));

export type TCareerSummaryHeaderP = {
    className?: string;
    startDate: string;
    endDate?: string;
    title: string;
    subtitle?: string;
};

export function CareerSummaryHeader(props: TReactFCP<TCareerSummaryHeaderP>): TReactFCR {
    const styles: UseStyles<ClassKey> = useStyles();
    const { className, startDate, endDate, title, subtitle }: TCareerSummaryHeaderP = props;
    return (
        <div className={clsx(styles.header, className)}>
            <Grid container justify='space-between'>
                <Grid item>
                    <div className={styles.titleContainer}>
                        <Typography variant='h4'>{title}</Typography>
                        {subtitle && (
                            <Typography className={styles.subtitle} variant='subtitle2' color='lighter'>
                                {subtitle}
                            </Typography>
                        )}
                    </div>
                </Grid>
                <Grid className={styles.dates} item container spacing={1} alignItems='center'>
                    <Grid item>
                        <Typography variant='body2' color='light'>{`${startDate}`}</Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant='body2' color='light'><LongArrowRightIcon fixedWidth /></Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant='body2' color='light'>{` ${endDate || 'Present'}`}</Typography>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
}
