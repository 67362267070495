import * as React from 'react';
import clsx from 'clsx';
import { UseStyles } from '../lib/types/mui';
import { Theme, Chip, makeStyles } from '@material-ui/core';
import { TReactFCP, TReactFCR } from '../lib/types/utils';
import { ChipProps } from '@material-ui/core/Chip';

type ClassKey = 'chip';
const useStyles = makeStyles<Theme, ClassKey>((theme: Theme) => ({
    chip: {
        ...theme.typography.sizing(1, 'mono'),
        borderRadius: theme.shape.borderRadius,
        borderColor: theme.palette.custom.card.labelArea.color,
        color: theme.palette.custom.pages.projects.previewCard.stack.color,
    }
}));

export type TTechStackTagP = ChipProps & {};

export function TechStackTag(props: TReactFCP<TTechStackTagP>): TReactFCR {
    const styles: UseStyles<ClassKey> = useStyles();
    const { className, ...chipProps }: TTechStackTagP = props;
    return (
        <Chip className={clsx(styles.chip, className)} variant='outlined' size='small' {...chipProps} />
    );
}
