import * as React from 'react';
import { TReactFCP, TReactFCR } from '../../lib/types/utils';
import { Typography } from '../Typography';
import { useStaticQuery, graphql } from 'gatsby';
import { GQLMsuSummaryQuery } from '../../lib/types/graphql/__generated__/gatsby.gql';
import { CareerSummary } from './CareerSummary';
import { CareerSummaryHeader } from './SummaryHeader';
import { Link } from '../Link';

export type TMsuSummaryP = {
    detailPageSlug?: string;
};

export function MsuSummary(props: TReactFCP<TMsuSummaryP>): TReactFCR {
    const { careerYaml }: GQLMsuSummaryQuery = useStaticQuery(graphql`
        query MsuSummary {
            careerYaml(cid: {eq: "msu"}) {
                startDate(formatString: "MMM YYYY")
                endDate(formatString: "MMM YYYY")
                title
                stack
            }
        }
    `);
    const { startDate, endDate, title, stack }: NonNullable<GQLMsuSummaryQuery['careerYaml']> = careerYaml!;
    return (
        <React.Fragment>
            <CareerSummary
                startDate={startDate!}
                endDate={endDate}
                title={title!}
                stack={(stack! as string[]) || []}
                gutterBottom
            >
                <Typography paragraph>
                    Throughout college I managed labs, networks, computers, and servers for faculity, staff, and
                    students at both the{` `}
                    <Link variant='external' to='https://pa.msu.edu/'>Physics and Astronomy</Link>
                    {` and the `}
                    <Link variant='external' to='https://www.canr.msu.edu/spdc/'>
                        Planning, Design, and Construction
                    </Link>
                    {` `}departments. I built custom systems for research, repaired broken systems (hardware and
                    software), performed data recovery, and authored articles and guides to make it easier for users to
                    manage their systems at each department.
                </Typography>
            </CareerSummary>
            <CareerSummaryHeader
                startDate={startDate!}
                endDate={endDate}
                title='Michigan State University'
                subtitle={'Bachelors of Computer Science and Engineering'}
            />
            {props.detailPageSlug && <Link variant='internal' to={props.detailPageSlug}>Learn more -></Link>}
        </React.Fragment>
    );
}
