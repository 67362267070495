import * as React from 'react';
import { TReactFCP, TReactFCR } from '../../lib/types/utils';
import { useStaticQuery, graphql } from 'gatsby';
import { GQLQntfySummaryQuery } from '../../lib/types/graphql/__generated__/gatsby.gql';
import { CareerSummary } from './CareerSummary';
import { List, ListItem, ListItemText } from '../List';
import { DoubleChevronRightIcon } from '../FAIcons';
import { Link } from '../Link';
import { Typography } from '../Typography';
import { TProjectWork } from '../../data/projects';
import { FluidObject } from 'gatsby-image';
import { ProjectsCardGrid } from '../ProjectsCardGrid';

type TProjectsEdge = GQLQntfySummaryQuery['projects']['edges'][0];

export type TQntfySummaryP = {};

export function QntfySummary(props: TReactFCP<TQntfySummaryP>): TReactFCR {
    const { careerYaml, projects, sitePage }: GQLQntfySummaryQuery = useStaticQuery(graphql`
        query QntfySummary {
            careerYaml(cid: {eq: "qntfy"}) {
                startDate(formatString: "MMM YYYY")
                endDate(formatString: "MMM YYYY")
                title
                stack
            }
            # I don't like this dependence on the name of the projects page component, but it works for now
            sitePage(internalComponentName: {eq: "ComponentProjects"}) {
                path
            }
            projects: allProjectsYaml(filter: {pid: {in: ["ourdatahelps", "quinn"]}}) {
                edges {
                    node {
                        pid
                        slug
                        title
                        category
                        type
                        spoiler
                        description
                        stack
                        links {
                            homepage
                            repository
                        }
                        isFeatured
                        featuredImage {
                            childImageSharp {
                                fluid(maxWidth: 768) {
                                    ...GatsbyImageSharpFluid_withWebp
                                }
                            }
                        }
                        featuredImageGrayscale: featuredImage {
                            childImageSharp {
                                fluid(maxWidth: 600, grayscale: true) {
                                    ...GatsbyImageSharpFluid_withWebp
                                }
                            }
                        }
                    }
                }
            }
        }
    `);
    const projectsSlug: string = sitePage!.path!;
    const { startDate, endDate, title, stack }: NonNullable<GQLQntfySummaryQuery['careerYaml']> = careerYaml!;
    return (
        <React.Fragment>
            <CareerSummary
                startDate={startDate!}
                endDate={endDate}
                title={title!}
                stack={(stack! as string[]) || []}
                gutterBottom
            >
                <Typography paragraph>
                    As one of the first employees at Qntfy my roles and responsibilities changed rapidly as the company
                    grew. From leading teams to pre/post sales engineering to architecting complex software systems,
                    I had a hand in building much of the company's foundational tech and software products.
                </Typography>
                <List
                    disablePadding
                    paragraph
                    icon={{
                        Component: DoubleChevronRightIcon,
                        props: { fixedWidth: true, size: 'xs' }
                    }}
                >
                    <ListItem disableGutters>
                        <ListItemText
                            primary={(
                                <React.Fragment>
                                    <Link variant='internal' to='/projects/ourdatahelps/'>
                                        Product owner, lead developer, and customer advocate
                                    </Link>
                                    {` of company’s largest public facing product, `}
                                    <Link variant='external' to='https://ourdatahelps.org'>OurDataHelps.org</Link>
                                    {`, a data-donation platform for mental health research`}
                                </React.Fragment>
                            )}
                        />
                    </ListItem>
                    <ListItem disableGutters>
                        <ListItemText
                            primary={(
                                <React.Fragment>
                                    {`Worked directly with Universities and organizations across the US to `}
                                    <Link variant='external' to='https://warriorsconnect.ourdatahelps.org'>
                                        design and  build
                                    </Link>
                                    {` `}
                                    <Link variant='external' to='https://uw.ourdatahelps.org'>customized</Link>
                                    {`, `}
                                    <Link variant='external' to='https://umd.ourdatahelps.org'>research-specific</Link>
                                    {` `}
                                    <Link variant='external' to='https://ocs.ourdatahelps.org'>deployments</Link>
                                    {` of `}
                                    <Link variant='external' to='https://ourdatahelps.org'>OurDataHelps.org</Link>
                                </React.Fragment>
                            )}
                        />
                    </ListItem>
                    <ListItem disableGutters>
                        <ListItemText
                            primary={(
                                <React.Fragment>
                                    <Link variant='internal' to='/projects/quinn/'>Led development</Link>
                                    {` of the data collection services, data processing engine, and Slack bot for `}
                                    <Link variant='external' to='https://quinn.ai'>Quinn</Link>
                                    {`, a team behavior and communication optimization analytic`}
                                </React.Fragment>
                            )}
                        />
                    </ListItem>
                    <ListItem disableGutters>
                        <ListItemText
                            primary={(
                                <React.Fragment>
                                    {`Designed, implemented, and integrated critical backend APIs and highly scalable
                                    data processing micro-services with frontend, user-facing services, led the
                                    program's product quality assurance group, and championed cross-company team
                                    integration for the final phase of `}
                                    <Link
                                        variant='external'
                                        to='https://www.darpa.mil/program/quantitative-crisis-response'
                                    >
                                        DARPA’s Quantitative Crisis Response program
                                    </Link>
                                </React.Fragment>
                            )}
                        />
                    </ListItem>
                    {/* <ListItem disableGutters>
                        <ListItemText
                            primary={(`
                                Champion and advocate of the design-first and test driven engineering methodology at
                                Qntfy from continuous integration to deployment
                            `)}
                        />
                    </ListItem> */}
                    <ListItem disableGutters>
                        <ListItemText
                            primary={`
                                Served as the technical lead, manager, and mentor of the Software Engineering team
                            `}
                        />
                    </ListItem>
                </List>
            </CareerSummary>
            <ProjectsCardGrid
                featuredProjects={
                    projects.edges.map((e: TProjectsEdge, index: number) => ({
                        pid: e.node.pid!,
                        card: {
                            // Must include trailing slash since the slugs we get from GQL don't have them
                            slug: `${projectsSlug}${e.node.slug!}/`,
                            category: e.node.category!,
                            type: e.node.type!,
                            title: e.node.title!,
                            spoiler: e.node.spoiler!,
                            description: e.node.description!,
                            stack: e.node.stack! as string[],
                            links: e.node.links! as TProjectWork['links'],
                            featuredImage: {
                                fluid: e.node.featuredImage!.childImageSharp!.fluid! as FluidObject
                            },
                            featuredImageGrayscale: {
                                fluid: e.node.featuredImageGrayscale!.childImageSharp!.fluid! as FluidObject
                            },
                            orientation: index % 2 === 0 ? 'left' : 'right'
                        }
                    }))
                }
            />
        </React.Fragment>
    );
}
